.fraud-recovery-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 20vw 3vw;
  min-height: 50vw;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.fraud-recovery-text-container {
  -webkit-transform: translateX(-3vw);
          transform: translateX(-3vw);
}

.icons-container {
  height: 40vw;
  gap: 1vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transform: scale(0.95) translateX(-1vw) translateY(1vw);
          transform: scale(0.95) translateX(-1vw) translateY(1vw);
}

.icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 6vw;
}

.icon-container > img {
  background-color: white;
  padding: 0.5vw;
  height: 5vw;
  -webkit-transform: translateY(0.5vw) translateX(-1vw);
          transform: translateY(0.5vw) translateX(-1vw);
  width: 5vw;
  -webkit-box-shadow: inset 0.1vw 0.1vw 1vw 0.1vw rgba(136, 136, 136, 0.3);
          box-shadow: inset 0.1vw 0.1vw 1vw 0.1vw rgba(136, 136, 136, 0.3);
}

.icon-container > .icon-text {
  margin-left: 1vw;
}

.icon-container > .icon-text > h5 {
  color: #ffca67;
  font-weight: 400;
  font-size: 1.3vw;
  letter-spacing: 0.1vw;
}

.icon-container > .icon-text > p {
  color: white;
  width: 30vw;
  font-size: 1vw;
  text-transform: capitalize;
}

.fraud-recovery-title {
  width: 30rem;
  font-weight: 600;
  font-size: 2.5em;
  margin-top: 3vw;
  color: white;
  -webkit-transform: translateX(-1.3vw);
          transform: translateX(-1.3vw);
}

.fraud-recovery-title span {
  color: #ffca67 !important;
}

.fraud-recovery-images {
  width: 50vw;
  -webkit-transform: translateY(5vw);
          transform: translateY(5vw);
}

.fraud-recovery-images > .img-1 {
  width: 35vw;
  height: 35vw;
  z-index: 2;
  padding: 5vw;
  position: absolute;
  -webkit-transform: translateX(-3vw) translateY(-3vw);
          transform: translateX(-3vw) translateY(-3vw);
}

.img-1-border {
  width: 25vw;
  height: 25vw;
  z-index: 0;
  position: absolute;
  border: 0.2vw solid white;
}

.img-2-border {
  width: 25vw;
  height: 25vw;
  z-index: 0;
  display: none;
  position: absolute;
  border: 0.2vw solid white;
}

.is-index-container {
  position: absolute;
  text-align: center;
  background-color: white;
  width: 14vw;
  z-index: 5;
  padding-bottom: 2.5vw;
  padding-top: 2.5vw;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transform: translateY(27.6vw) translateX(0.3vw);
          transform: translateY(27.6vw) translateX(0.3vw);
  border-radius: 0.5vw;
  -webkit-box-shadow: -2px 2px 0px 3px #50200420;
  box-shadow: -2px 2px 0px 3px #50200420;
}

.is-index-container > h5 {
  color: #bc9c60;
  font-size: 2vw;
}

.is-index-container > h6 {
  font-size: 1vw;
}

.fraud-recovery-images > .img-2 {
  position: absolute;
  z-index: 3;
  width: 28vw;
  height: 28vw;
  -webkit-transform: translateY(14vw) translateX(15vw);
          transform: translateY(14vw) translateX(15vw);
  border: 1vw solid white;
}

.icons-container > button {
  -ms-flex-item-align: start;
  align-self: flex-start;
  border: 1px solid #ffca67;
  background-color: #00000000;
  color: white;
  padding: 0.5vw;
  font-size: 1.5vw;
  font-weight: 600;
  width: 15vw;
  border-radius: 0.4vw;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translateX(-1vw);
          transform: translateX(-1vw);
}

.icons-container > button:hover {
  background-color: #ffca67;
  color: #1e1e1e;
}

.icons-container > button:active {
  -webkit-transition: 80ms;
  transition: 80ms;
  background-color: white;
}

@media (max-width: 990px) {
  .fraud-recovery-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 280vw;
    padding-top: 10vw;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .img-2-border {
    display: inline;
    -webkit-transform: translateX(40vw);
            transform: translateX(40vw);
  }
  .img-1-border {
    -webkit-transform: translateX(5vw);
            transform: translateX(5vw);
  }
  .fraud-recovery-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: scale(1.33) translateY(15vw) translateX(-9vw);
            transform: scale(1.33) translateY(15vw) translateX(-9vw);
    -ms-flex-item-align: center;
        align-self: center;
    justify-self: center;
    margin: 5vw;
  }
  .fraud-recovery-images > .img-2 {
    position: relative;
    -webkit-transform: translateX(-3vw) translateY(-3vw);
            transform: translateX(-3vw) translateY(-3vw);
    border: none;
    width: 35vw;
    height: 35vw;
    z-index: 2;
    padding: 5vw;
  }
  .fraud-recovery-images > .img-1 {
    position: relative;
    width: 35vw;
    height: 35vw;
  }
  .is-index-container {
    display: none;
  }
  .fraud-recovery-title {
    -webkit-transform: translateY(-6vw) translateX(17vw) scale(1.2);
            transform: translateY(-6vw) translateX(17vw) scale(1.2);
    font-size: 4.5vw !important;
    width: 70%;
    position: absolute;
    padding-left: 3vw;
    text-align: center;
  }
  .fraud-recovery-title::before {
    -webkit-transform: translateX(-6vw) translateY(4.8vw) !important;
            transform: translateX(-6vw) translateY(4.8vw) !important;
  }
  .icons-container {
    width: 100%;
    -webkit-transform: translateX(2.9vw) translateY(17.5vw) scale(0.85);
            transform: translateX(2.9vw) translateY(17.5vw) scale(0.85);
  }
  .icon-container {
    height: 50vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid white;
    margin-bottom: 10vw;
    margin-top: 10vw;
    padding: 5vw;
  }
  .icons-container > button {
    width: 100%;
    font-size: 7vw;
    padding: 3vw;
    -webkit-transform: translateY(0vw);
            transform: translateY(0vw);
  }
  .icon-container > .icon-text > p {
    font-size: 4.5vw;
    width: 100%;
    -webkit-transform: translateY(2vw);
            transform: translateY(2vw);
    font-weight: 400;
    margin-bottom: 4vw;
    text-align: justify;
  }
  .icon-container > .icon-text > h5 {
    -webkit-transform: translateY(2vw);
            transform: translateY(2vw);
    text-align: center;
    font-size: 6.5vw !important;
    width: 100%;
    padding: 3vw;
  }
  .icon-container > img {
    width: 20vw;
    height: 20vw;
    -o-object-fit: scale-down;
       object-fit: scale-down;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: translateY(-30vw);
            transform: translateY(-30vw);
  }
  .icon-container > .icon-text {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .fraud-recovery-images {
    display: none;
  }
}

@media (min-width: 1920px) {
  .fraud-recovery-container {
    -webkit-transform: scale(0.92);
            transform: scale(0.92);
    gap: 5vw;
  }
}
