html {
  max-width: 100vw !important;
  overflow-x: hidden;
}

.App {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.9)), to(rgba(255, 255, 255, 0.95))), url("../src/media/img/global_map.png");
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.95)), url("../src/media/img/global_map.png");
  font-family: "Oswald", sans-serif;
}

.logo {
  height: 20em;
  width: 20em;
  -webkit-transform: translateY(-5vw);
          transform: translateY(-5vw);
  background-color: white;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

section {
  min-height: 15em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.form-section {
  text-align: center;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.form-section form {
  margin: 5em;
  width: 55em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1em;
  padding: 2em;
  background-color: white;
  -webkit-box-shadow: 0em 0em 0.5em 0.01em black;
          box-shadow: 0em 0em 0.5em 0.01em black;
  padding-left: 10em;
  font-size: 0.8vw;
  padding-right: 10em;
}

.form-section form h1 {
  color: #ffca67;
  font-size: 3em;
  width: 20em;
  text-align: left;
  -webkit-transform: translateX(-1.7em);
          transform: translateX(-1.7em);
}

.form-section form h2 {
  font-size: 1.7em;
}

.form-section form input {
  width: 100%;
  height: 3em;
  font-size: 1.5em;
  border: 0.1em solid #b3b3b3;
  border-radius: 0.25em;
  padding: 1em;
}

.form-section form select {
  width: 100%;
  height: 3em;
  font-size: 1.5em;
  border: 0.1em solid #b3b3b3;
  border-radius: 0.25em;
  background-color: white;
  color: gray;
  padding-left: 0.7em;
}

.form-section form .submit-btn {
  width: 100%;
  height: 4em;
  font-size: 1.5em;
  color: white;
  background-color: #bc9d60;
  border: 0.1em solid rgba(179, 179, 179, 0);
  border-radius: 0.25em;
}

.form-section form .submit-btn:disabled {
  opacity: 0.5;
}

.form-section form .date-picker-container {
  border: 0.1em solid #b3b3b3;
  border-radius: 0.25em;
  height: 3em;
  width: 100%;
  background-color: white;
}

.form-section form .date-picker-container > * {
  width: 100%;
  -webkit-transform: translateY(-0.3vw);
          transform: translateY(-0.3vw);
}

.form-section form .date-picker-container * {
  border: none !important;
}

.form-section form .date-picker-container input {
  height: 2vw;
  color: #858585;
  border: none;
}

.form-section form .recaptcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about-section {
  background-color: #b68c3d;
  max-width: 100vw;
}

.button-section {
  background-color: #b68c3d;
  min-height: 10em !important;
}

.button-section button {
  border-radius: 0.25em;
  width: 35em;
  background-color: #ffca67;
  padding: 1em;
  border: 0.1em solid #ffca67;
  font-size: 1.5em;
  color: #b68c3d;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.button-section button:hover {
  color: white;
  border: 0.1em solid white;
  background-color: #b68c3d;
}

.why-us-section {
  background-color: #b68c3d;
  width: 100em;
  color: white;
}

.why-us-section .services {
  margin-top: 5em;
  margin-bottom: 5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  width: 100em;
}

.why-us-section .services .service {
  width: 20em;
  height: 12.5em;
  border: 0.1em solid white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 5em;
  gap: 1em;
}

.why-us-section .services .service img {
  position: absolute;
  width: 7.5em;
  height: 7.5em;
  -webkit-transform: translateX(-4em) translateY(-7em);
          transform: translateX(-4em) translateY(-7em);
  background-color: #b68c3d;
  border: 0.1em solid white;
  border-radius: 50%;
  padding: 1em;
}

.why-us-section h2 {
  font-size: 3.5em !important;
}

.why-us-section h5 {
  text-align: center;
  font-size: 3em;
}

.why-us-section h6 {
  text-align: center;
  font-size: 1.5em;
}

.trusted {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 3vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.trusted p {
  font-size: 1.07vw;
  width: 45vw;
  font-weight: 400;
  text-align: justify;
}

.trusted-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3vw;
}

.trusted-logos img {
  -webkit-filter: brightness(0.2);
          filter: brightness(0.2);
  width: 10vw;
  max-height: 5vw;
  -webkit-transform: translateY(-0.5vw);
          transform: translateY(-0.5vw);
}

.testimonials-section {
  margin-top: 5em;
  margin-bottom: 5em;
  gap: 5em;
  font-size: 0.8em;
}

.testimonial {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1em;
}

.testimonial h2 {
  text-align: center;
  background-color: #b68c3d;
  color: white;
  padding: 1em;
}

.testimonial video {
  max-height: 20em;
  width: 30em;
  background-color: rgba(0, 0, 0, 0.85);
}

.testimonial .video-container {
  background-color: #b68c3d;
  -webkit-box-shadow: 0em 0em 0.5em 0.01em #3b3131;
          box-shadow: 0em 0em 0.5em 0.01em #3b3131;
}

.testimonial .review-container {
  background-color: white;
  text-align: center;
  -webkit-box-shadow: 0em 0em 0.5em 0.01em #3b3131;
          box-shadow: 0em 0em 0.5em 0.01em #3b3131;
  width: 40em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.testimonial .review-container p {
  font-size: 3em;
  color: transparent;
}

.testimonial .review-container h3 {
  font-size: 5em;
}

.testimonial .review-container h4 {
  font-size: 3em;
}

.testimonial.inverted {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.thank-you-page {
  font-family: "Oswald", sans-serif;
  width: 100vw;
  height: 100vh;
  background: #c3a15c;
}

.thank-you-page .image-overlay {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#a1864fe0), to(#c3a15cd2)), url(media/img/city.jpg);
  background-image: linear-gradient(#a1864fe0, #c3a15cd2), url(media/img/city.jpg);
  background-size: cover;
  width: 100vw;
  mix-blend-mode: luminosity;
  height: 100vh;
  z-index: 50;
  position: absolute;
}

.thank-you-page .handshake {
  mix-blend-mode: luminosity;
  width: 6em;
  height: 6em;
  background-color: #d3bf96;
  border: 0.3em solid #d3bf96;
  border-radius: 50%;
  z-index: 100;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  -webkit-animation: fadeUp 0.5s ease normal;
          animation: fadeUp 0.5s ease normal;
}

.thank-you-page .text {
  z-index: 100;
  text-align: center;
  -webkit-animation: fadeUp 0.7s ease normal;
          animation: fadeUp 0.7s ease normal;
  -webkit-filter: brightness(10);
          filter: brightness(10);
}

.thank-you-page .text h1 {
  font-size: 4.5vw;
  margin: 1rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.thank-you-page .text h3 {
  font-size: 2vw;
}

.thank-you-page button {
  z-index: 100;
  background: #c3a15cce;
  border: 0.2rem solid white;
  padding: 1rem;
  margin: 1rem;
  color: white;
  font-size: 1.5rem;
  border-radius: 2em;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  margin-top: 2rem;
  font-weight: 500;
  -webkit-animation: fadeUp 1s ease normal;
          animation: fadeUp 1s ease normal;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.thank-you-page button:hover {
  background: white;
  color: black;
}

.thank-you-page button:active {
  -webkit-transition: 0.1s;
  transition: 0.1s;
  background: #e2c489ce;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  font-weight: 100;
}

@-webkit-keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5em);
            transform: translateY(5em);
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0em);
            transform: translateY(0em);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(5em);
            transform: translateY(5em);
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0em);
            transform: translateY(0em);
  }
}

@media (max-width: 990px) {
  .logo {
    display: none;
  }
  .trusted-logos-top {
    -webkit-transform: none !important;
            transform: none !important;
    padding-bottom: 5em;
    gap: 5em !important;
  }
  .icon-container img {
    width: 12em;
    min-height: 12em;
  }
  .form-section form {
    padding-left: 5em;
    font-size: 1.6vw;
    padding-right: 5em;
  }
  .form-section h1 {
    -webkit-transform: none !important;
            transform: none !important;
  }
  .form-section input {
    font-size: 4vw !important;
  }
  .form-section select {
    font-size: 4vw !important;
  }
  .form-section button {
    font-size: 4vw !important;
  }
  .form-section p {
    font-size: 2.5vw;
  }
  .form-section .recaptcha {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  .trusted {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .trusted p {
    font-size: 2.9vw;
    width: 90%;
  }
  .trusted-logos img {
    -webkit-filter: brightness(0.2);
            filter: brightness(0.2);
    width: 25vw;
    max-height: 5vw;
    margin-bottom: 5vw;
    -webkit-transform: translateY(-0.5vw);
            transform: translateY(-0.5vw);
  }
  .why-us-section {
    font-size: 2em;
    width: 50em;
  }
  .why-us-section .services {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 7em;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 42.5em;
  }
  .why-us-section .services .service {
    width: 100%;
  }
  .testimonials-section {
    font-size: 1.2vw;
  }
  .testimonials-section .testimonial {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .testimonials-section .testimonial .review-container {
    width: 70em;
    max-height: 40em;
  }
  .testimonials-section .testimonial video {
    width: 70em;
    max-height: 40em;
  }
  .testimonials-section .testimonial h3 {
    font-size: 10em;
  }
  .testimonials-section .testimonial h4 {
    font-size: 5em;
  }
  .testimonials-section .testimonial h2 {
    padding: 0.1em;
  }
  .button-section {
    font-size: 2em;
    height: 20em;
  }
  .button-section button {
    font-size: 3em;
    width: 90%;
  }
  .handshake {
    width: 30em !important;
    border: 1.5em solid #d3bf96 !important;
    height: 30em !important;
  }
  .thank-you-page .text h1 {
    font-size: 13em;
  }
  .thank-you-page .text h3 {
    font-size: 8em;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .thank-you-page button {
    font-size: 8em;
  }
}

.trusted-logos-parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.trusted-logos-parent .trusted-logos-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 3vw;
}

.trusted-logos-parent .trusted-logos-top img {
  width: 6em;
  max-height: 6em;
}

label[for="honeypot"],
#honeypot {
  display: none;
  visibility: hidden;
}
