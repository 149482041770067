$brandLight: #ffca67;
$brandDark: #b68c3d;
html {
  max-width: 100vw !important;
  overflow-x: hidden;
}
.App {
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.95)
    ),
    url("../src/media/img/global_map.png");
  font-family: "Oswald", sans-serif;
}
.logo {
  height: 20em;
  width: 20em;
  transform: translateY(-5vw);
  background-color: white;
  object-fit: cover;
  border-radius: 50%;
}
section {
  min-height: 15em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
}
.form-section {
  text-align: center;
  flex-direction: row !important;
  form {
    margin: 5em;
    width: 55em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em;
    background-color: white;
    box-shadow: 0em 0em 0.5em 0.01em black;
    padding-left: 10em;
    font-size: 0.8vw;
    padding-right: 10em;

    h1 {
      color: $brandLight;
      font-size: 3em;
      width: 20em;
      text-align: left;
      transform: translateX(-1.7em);
    }
    h2 {
      font-size: 1.7em;
    }

    input {
      width: 100%;
      height: 3em;
      font-size: 1.5em;
      border: 0.1em solid rgb(179, 179, 179);
      border-radius: 0.25em;
      padding: 1em;
    }
    select {
      width: 100%;
      height: 3em;
      font-size: 1.5em;
      border: 0.1em solid rgb(179, 179, 179);
      border-radius: 0.25em;
      background-color: white;
      color: gray;
      padding-left: 0.7em;
    }
    .submit-btn {
      width: 100%;
      height: 4em;
      font-size: 1.5em;
      color: white;
      background-color: #bc9d60;
      border: 0.1em solid rgba(179, 179, 179, 0);
      border-radius: 0.25em;
    }
    .submit-btn:disabled {
      opacity: 0.5;
    }
    .date-picker-container {
      border: 0.1em solid rgb(179, 179, 179);
      border-radius: 0.25em;
      height: 3em;
      width: 100%;
      background-color: white;
    }
    .date-picker-container > * {
      width: 100%;
      transform: translateY(-0.3vw);
    }
    .date-picker-container * {
      border: none !important;
    }
    .date-picker-container input {
      height: 2vw;
      color: #858585;
      border: none;
    }
    .recaptcha {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.about-section {
  background-color: $brandDark;
  max-width: 100vw;
}
.button-section {
  background-color: $brandDark;
  min-height: 10em !important;

  button {
    border-radius: 0.25em;

    width: 35em;
    background-color: $brandLight;
    padding: 1em;
    border: 0.1em solid $brandLight;
    font-size: 1.5em;
    color: $brandDark;
    font-weight: 600;
    transition: 0.3s;
  }
  button:hover {
    color: white;
    border: 0.1em solid white;
    background-color: $brandDark;
  }
}
.why-us-section {
  background-color: $brandDark;
  width: 100em;
  color: white;
  .services {
    margin-top: 5em;
    margin-bottom: 5em;
    display: flex;
    justify-content: space-evenly;
    width: 100em;
    .service {
      width: 20em;
      height: 12.5em;
      border: 0.1em solid white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 5em;
      gap: 1em;
      img {
        position: absolute;
        width: 7.5em;
        height: 7.5em;
        transform: translateX(-4em) translateY(-7em);
        background-color: #b68c3d;
        border: 0.1em solid white;
        border-radius: 50%;
        padding: 1em;
      }
    }
  }
  h2 {
    font-size: 3.5em !important;
  }
  h5 {
    text-align: center;
    font-size: 3em;
  }
  h6 {
    text-align: center;
    font-size: 1.5em;
  }
}
.trusted {
  justify-content: center;
  gap: 3vw;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.trusted {
  p {
    font-size: 1.07vw;
    width: 45vw;
    font-weight: 400;
    text-align: justify;
  }
}
.trusted-logos {
  display: flex;
  gap: 3vw;
  img {
    filter: brightness(0.2);
    width: 10vw;
    max-height: 5vw;
    transform: translateY(-0.5vw);
  }
}
.testimonials-section {
  margin-top: 5em;
  margin-bottom: 5em;
  gap: 5em;
  font-size: 0.8em;
}
.testimonial {
  display: flex;
  gap: 1em;
  h2 {
    text-align: center;
    background-color: $brandDark;
    color: white;
    padding: 1em;
  }
  video {
    max-height: 20em;
    width: 30em;
    background-color: rgba(0, 0, 0, 0.85);
  }
  .video-container {
    background-color: $brandDark;
    box-shadow: 0em 0em 0.5em 0.01em rgb(59, 49, 49);
  }
  .review-container {
    background-color: white;
    text-align: center;
    box-shadow: 0em 0em 0.5em 0.01em rgb(59, 49, 49);
    width: 40em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    p {
      font-size: 3em;
      color: transparent;
    }
    h3 {
      font-size: 5em;
    }
    h4 {
      font-size: 3em;
    }
  }
}
.testimonial.inverted {
  flex-direction: row-reverse;
}
.thank-you-page {
  font-family: "Oswald", sans-serif;
  width: 100vw;
  height: 100vh;
  background: #c3a15c;
  .image-overlay {
    background-image: linear-gradient(#a1864fe0, #c3a15cd2),
      url(media/img/city.jpg);
    background-size: cover;
    width: 100vw;
    mix-blend-mode: luminosity;
    height: 100vh;
    z-index: 50;
    position: absolute;
  }
  .handshake {
    mix-blend-mode: luminosity;
    width: 6em;
    height: 6em;
    background-color: #d3bf96;
    border: 0.3em solid #d3bf96;
    border-radius: 50%;
    z-index: 100;
    filter: brightness(1.2);
    animation: fadeUp 0.5s ease normal;
  }
  .text {
    z-index: 100;
    text-align: center;
    animation: fadeUp 0.7s ease normal;
    filter: brightness(10);
    h1 {
      font-size: 4.5vw;
      margin: 1rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
    h3 {
      font-size: 2vw;
    }
  }
  button {
    z-index: 100;
    background: #c3a15cce;
    border: 0.2rem solid white;
    padding: 1rem;
    margin: 1rem;
    color: white;
    font-size: 1.5rem;
    border-radius: 2em;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    margin-top: 2rem;
    font-weight: 500;
    animation: fadeUp 1s ease normal;
    transition: 0.3s;
  }
  button:hover {
    background: white;
    color: black;
  }
  button:active {
    transition: 0.1s;
    background: #e2c489ce;
    transform: scale(0.9);
    font-weight: 100;
  }
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(5em);
  }

  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
}
@media (max-width: 990px) {
  .logo {
    display: none;
  }
  .trusted-logos-top {
    transform: none !important;
    padding-bottom: 5em;
    gap: 5em !important;
  }
  .icon-container {
    img {
      width: 12em;
      min-height: 12em;
    }
  }
  .form-section {
    form {
      padding-left: 5em;
      font-size: 1.6vw;
      padding-right: 5em;
    }
    h1 {
      transform: none !important;
    }
    input {
      font-size: 4vw !important;
    }
    select {
      font-size: 4vw !important;
    }
    button {
      font-size: 4vw !important;
    }
    p {
      font-size: 2.5vw;
    }
    .recaptcha {
      transform: scale(0.8);
    }
  }

  .trusted {
    flex-direction: column;
    p {
      font-size: 2.9vw;
      width: 90%;
    }
  }
  .trusted-logos {
    img {
      filter: brightness(0.2);
      width: 25vw;
      max-height: 5vw;
      margin-bottom: 5vw;
      transform: translateY(-0.5vw);
    }
  }
  .why-us-section {
    font-size: 2em;
    width: 50em;
    .services {
      flex-direction: column;
      gap: 7em;
      align-items: center;
      width: 42.5em;
      .service {
        width: 100%;
      }
    }
  }
  .testimonials-section {
    font-size: 1.2vw;
    .testimonial {
      flex-direction: column;
      .review-container {
        width: 70em;
        max-height: 40em;
      }
      video {
        width: 70em;
        max-height: 40em;
      }
      h3 {
        font-size: 10em;
      }
      h4 {
        font-size: 5em;
      }
      h2 {
        padding: 0.1em;
      }
    }
  }

  .button-section {
    font-size: 2em;
    height: 20em;
    button {
      font-size: 3em;
      width: 90%;
    }
  }
  .handshake {
    width: 30em !important;
    border: 1.5em solid #d3bf96 !important;
    height: 30em !important;
  }
  .thank-you-page .text h1 {
    font-size: 13em;
  }
  .thank-you-page .text h3 {
    font-size: 8em;
    transform: scale(0.9);
  }
  .thank-you-page button {
    font-size: 8em;
  }
}

.trusted-logos-parent {
  display: flex;
  flex-direction: column;

  .trusted-logos-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3vw;

    img {
      width: 6em;
      max-height: 6em;
    }
  }
}

label[for="honeypot"],
#honeypot {
  display: none;
  visibility: hidden;
}
